<template>
  <navigation-bar></navigation-bar>
  <router-view></router-view>
  <footer-bar></footer-bar>
</template>

<script>
import FooterBar from "./components/FooterBar.vue";
import NavigationBar from "./components/NavigationBar.vue";
export default {
  name: "App",
  components: { NavigationBar, FooterBar },
};
</script>

<style>
@font-face {
  font-family: "DM Service Display";
  src: local("DM Service Display"),
    url(./assets/fonts/DMSerifDisplay-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}
body {
  font-family: Open Sans;
}
h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: "DM Service Display";
}
h2 {
  font-size: 72px;
  line-height: 76px;
}

.section {
  padding: 100px 0px;
}
h4 {
  font-size: 26px;
}
label {
  font-size: 22px;
}
.d-relative {
  position: relative;
}

@media screen and (max-width: 440px) {
  h2 {
    font-size: 36px;
    line-height: 40px;
  }
  svg {
    max-width: 100% !important;
  }
}
.post-body img {
  width: 100%;
  height: auto;
  margin: 15px 0px;
}
.post-body h2 {
  font-size: 3rem;
  line-height: 3rem;
  margin-top: 50px;
  margin-bottom: 15px;
}

.post-body h2,
.post-body h3,
.post-body h4 {
  font-size: 1.5rem;
  font-family: "Open Sans";
  font-weight: bold;
  color: #333;
  margin: 25px 0px;
}

.carousel__pagination-button,
.carousel__next,
.carousel__prev {
  background-color: #ddd !important;
}
button.carousel__pagination-button--active {
  background-color: #888 !important;
}
</style>
