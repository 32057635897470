<template>
  <a class="social-icon" target="_blank" v-bind:href="url">
    <img
      :src="getSource(icon)"
      class="d-inline-block align-text-top"
      :class="{ large: large }"
    />
  </a>
</template>

<script>
export default {
  name: "social-icon",
  props: { url: String, icon: String, large: Boolean },
  methods: {
    getSource(icon) {
      var images = require.context("../assets/img", false, /\.png$/);
      return images("./" + icon + ".png");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  width: auto;
  height: 40px;
}
.large {
  height: 65px;
}
@media screen and (max-width: 440px) {
  .large {
    height: 20px;
  }
}
</style>
