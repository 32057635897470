<template>
  <div id="home">
    <hero-section />
    <strategy-section />
    <expertise-section />
    <references-section />
    <book-section />
    <blog-section />
  </div>
</template>

<script>
import BookSection from "./BookSection.vue";
import ExpertiseSection from "./ExpertiseSection.vue";
import HeroSection from "./HeroSection.vue";
import ReferencesSection from "./ReferencesSection.vue";
import StrategySection from "./StrategySection.vue";
import BlogSection from "./BlogSection.vue";

export default {
  name: "Home",
  props: {},
  data() {
  return {
        title: 'David Minkovski - IT-Consultant & Solution Architect',
        description: 'My aim is to guide companies towards success with cutting-edge technology and software, while providing customers with an optimal user experience.'
      }
    },
  watch: {
    title(newTitle) {
      document.title = newTitle;
    },
    description(newDescription) {
      document.querySelector('meta[name="description"]').setAttribute('content', newDescription);
    }
  },
  mounted() {
    // Set initial values
    document.title = this.title;
    document.querySelector('meta[name="description"]').setAttribute('content', this.description);
  },
  components: {
    HeroSection,
    StrategySection,
    ExpertiseSection,
    ReferencesSection,
    BookSection,
    BlogSection,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
