<template>
  <h2>{{ text }}</h2>
</template>

<script>
export default {
  name: "headline-text",
  props: {
    text: String,
  },
};
</script>
<style scoped>
h2 {
  font-size: 48px;
}
</style>
