<template>
  <p>{{ text }}</p>
</template>

<script>
export default {
  name: "body-text",
  props: {
    text: String,
  },
};
</script>
<style scoped>
p {
  font-size: 16px;
  text-align: justify;
}
</style>
