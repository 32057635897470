<template>
  <div id="strategy" class="w-100 section">
    <div class="container h-100">
      <div class="row">
        <div class="col col-12 col-md-6">
          <headline-text v-html="$t('strategy.title')" />

          <div
            class="mb-5 mt-5"
            v-for="(text, index) in texts"
            v-bind:key="index"
          >
            <h4 class="title" v-html="$t(text.title)" />
            <p class="body" v-html="$t(text.body)" />
          </div>
        </div>
        <div class="col col-12  col-md-6 text-end pe-0">
          <svg
            width="550"
            height="511"
            viewBox="0 0 550 511"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_df)">
              <circle cx="333" cy="200" r="106" fill="#FFF6E6" />
              <circle
                cx="333"
                cy="200"
                r="106"
                fill="url(#paint0_linear)"
                fill-opacity="0.724"
              />
            </g>
            <g>
              <path
                d="M157.555 178.007H300.945V266.802C300.945 268.61 300.225 270.343 298.944 271.622C297.663 272.9 295.926 273.618 294.114 273.618H164.386C162.574 273.618 160.837 272.9 159.556 271.622C158.275 270.343 157.555 268.61 157.555 266.802V178.007Z"
                fill="white"
              />
              <path
                d="M294.114 274.426H164.386C162.358 274.421 160.415 273.614 158.983 272.181C157.55 270.748 156.746 268.807 156.746 266.784V178.007C156.746 177.791 156.831 177.584 156.982 177.429C157.133 177.275 157.339 177.186 157.555 177.181H300.945C301.164 177.181 301.374 177.268 301.53 177.423C301.685 177.578 301.772 177.788 301.772 178.007V266.784C301.772 268.81 300.965 270.754 299.529 272.187C298.093 273.62 296.145 274.426 294.114 274.426ZM158.382 178.832V266.73C158.382 268.321 159.014 269.846 160.139 270.973C161.265 272.099 162.792 272.735 164.386 272.739H294.114C295.711 272.739 297.243 272.106 298.372 270.979C299.502 269.852 300.136 268.324 300.136 266.73V178.832H158.382Z"
                fill="#CCCCCC"
              />
              <path
                d="M164.386 165.306H294.114C295.926 165.306 297.663 166.024 298.944 167.303C300.225 168.581 300.945 170.315 300.945 172.123V178.007H157.555V172.123C157.555 170.315 158.275 168.581 159.556 167.303C160.837 166.024 162.574 165.306 164.386 165.306Z"
                fill="white"
              />
              <path
                d="M300.945 178.832H157.555C157.339 178.827 157.133 178.738 156.982 178.584C156.831 178.43 156.746 178.222 156.746 178.007V172.123C156.751 170.102 157.557 168.166 158.989 166.737C160.421 165.308 162.361 164.504 164.386 164.499H294.114C296.136 164.508 298.072 165.315 299.499 166.743C300.927 168.172 301.731 170.105 301.736 172.123V178.007C301.736 178.219 301.654 178.424 301.506 178.578C301.359 178.731 301.158 178.823 300.945 178.832ZM158.382 177.181H300.136V172.123C300.131 170.532 299.495 169.008 298.366 167.885C297.237 166.762 295.708 166.131 294.114 166.131H164.386C162.795 166.136 161.27 166.769 160.146 167.891C159.021 169.014 158.387 170.535 158.382 172.123V177.181Z"
                fill="#CCCCCC"
              />
              <path
                d="M192.859 213.543C197.962 213.543 202.098 209.414 202.098 204.322C202.098 199.23 197.962 195.102 192.859 195.102C187.756 195.102 183.62 199.23 183.62 204.322C183.62 209.414 187.756 213.543 192.859 213.543Z"
                fill="#FFF0D4"
              />
              <path
                d="M176.07 232.199C176.212 227.847 178.045 223.722 181.179 220.695C184.314 217.667 188.506 215.975 192.868 215.975C197.23 215.975 201.422 217.667 204.556 220.695C207.691 223.722 209.523 227.847 209.666 232.199H176.07Z"
                fill="#FFF0D4"
              />
              <path
                d="M265.641 213.543C270.744 213.543 274.881 209.414 274.881 204.322C274.881 199.23 270.744 195.102 265.641 195.102C260.538 195.102 256.402 199.23 256.402 204.322C256.402 209.414 260.538 213.543 265.641 213.543Z"
                fill="#FFF0D4"
              />
              <path
                d="M248.853 232.199C248.995 227.847 250.827 223.722 253.962 220.695C257.097 217.667 261.288 215.975 265.651 215.975C270.013 215.975 274.204 217.667 277.339 220.695C280.474 223.722 282.306 227.847 282.449 232.199H248.853Z"
                fill="#FFF0D4"
              />
              <path
                d="M245.023 205.147H223.453C223.234 205.147 223.023 205.06 222.868 204.906C222.713 204.751 222.626 204.541 222.626 204.322C222.626 204.103 222.713 203.894 222.868 203.739C223.023 203.584 223.234 203.497 223.453 203.497H243.118L238.822 198.995C238.746 198.919 238.685 198.83 238.644 198.732C238.603 198.633 238.582 198.527 238.582 198.421C238.582 198.314 238.603 198.208 238.644 198.109C238.685 198.011 238.746 197.922 238.822 197.846C238.979 197.696 239.188 197.612 239.406 197.612C239.624 197.612 239.833 197.696 239.99 197.846L245.599 203.748C245.711 203.861 245.787 204.006 245.816 204.163C245.845 204.32 245.826 204.482 245.76 204.627C245.701 204.776 245.6 204.904 245.469 204.997C245.338 205.089 245.183 205.141 245.023 205.147Z"
                fill="#CCCCCC"
              />
              <path
                d="M239.631 210.403C239.408 210.401 239.194 210.311 239.038 210.152C238.883 209.997 238.796 209.788 238.796 209.569C238.796 209.351 238.883 209.141 239.038 208.986L244.43 203.748C244.589 203.605 244.798 203.53 245.011 203.537C245.225 203.544 245.428 203.634 245.577 203.787C245.726 203.941 245.809 204.145 245.81 204.359C245.811 204.572 245.729 204.778 245.581 204.932L240.188 210.17C240.038 210.315 239.839 210.399 239.631 210.403Z"
                fill="#CCCCCC"
              />
              <path
                d="M234.795 212.054H213.225C213.065 212.056 212.908 212.012 212.774 211.926C212.64 211.839 212.534 211.715 212.47 211.569C212.406 211.421 212.387 211.256 212.415 211.097C212.444 210.937 212.519 210.79 212.632 210.672L218.24 204.789C218.313 204.706 218.403 204.64 218.502 204.592C218.602 204.545 218.71 204.518 218.821 204.513C218.931 204.508 219.041 204.526 219.145 204.564C219.248 204.602 219.343 204.661 219.424 204.737C219.504 204.812 219.569 204.903 219.613 205.004C219.658 205.104 219.682 205.213 219.684 205.323C219.686 205.433 219.666 205.543 219.625 205.645C219.583 205.747 219.522 205.84 219.444 205.919L215.13 210.403H234.795C235.013 210.408 235.221 210.496 235.375 210.65C235.529 210.804 235.618 211.011 235.622 211.229C235.622 211.447 235.535 211.657 235.38 211.812C235.225 211.967 235.015 212.054 234.795 212.054Z"
                fill="#CCCCCC"
              />
              <path
                d="M218.599 217.292C218.495 217.293 218.392 217.273 218.296 217.233C218.201 217.193 218.114 217.134 218.042 217.058L212.65 211.838C212.499 211.681 212.415 211.473 212.415 211.255C212.415 211.038 212.499 210.829 212.65 210.672C212.805 210.518 213.015 210.431 213.234 210.431C213.453 210.431 213.663 210.518 213.818 210.672L219.211 215.91C219.287 215.986 219.347 216.075 219.388 216.173C219.43 216.272 219.451 216.378 219.451 216.484C219.451 216.591 219.43 216.697 219.388 216.795C219.347 216.894 219.287 216.983 219.211 217.058C219.129 217.136 219.034 217.196 218.929 217.237C218.824 217.277 218.712 217.295 218.599 217.292Z"
                fill="#CCCCCC"
              />
              <path
                d="M272.004 243.051H186.495C186.257 243.051 186.028 242.957 185.86 242.789C185.691 242.62 185.597 242.392 185.597 242.154C185.601 241.918 185.697 241.692 185.865 241.525C186.033 241.358 186.259 241.262 186.495 241.257H272.004C272.243 241.257 272.471 241.352 272.64 241.52C272.809 241.688 272.903 241.916 272.903 242.154C272.903 242.392 272.809 242.62 272.64 242.789C272.471 242.957 272.243 243.051 272.004 243.051Z"
                fill="#D1D3D4"
              />
              <path
                d="M272.004 249.114H186.495C186.257 249.114 186.028 249.02 185.86 248.852C185.691 248.684 185.597 248.455 185.597 248.217C185.601 247.981 185.697 247.756 185.865 247.588C186.033 247.421 186.259 247.325 186.495 247.321H272.004C272.243 247.321 272.471 247.415 272.64 247.583C272.809 247.751 272.903 247.98 272.903 248.217C272.903 248.455 272.809 248.684 272.64 248.852C272.471 249.02 272.243 249.114 272.004 249.114Z"
                fill="#D1D3D4"
              />
              <path
                d="M263.214 255.249H186.495C186.257 255.249 186.028 255.155 185.86 254.987C185.691 254.818 185.597 254.59 185.597 254.352C185.601 254.116 185.697 253.89 185.865 253.723C186.033 253.556 186.259 253.46 186.495 253.456H263.214C263.451 253.46 263.677 253.556 263.845 253.723C264.012 253.89 264.109 254.116 264.113 254.352C264.113 254.59 264.019 254.818 263.85 254.987C263.681 255.155 263.453 255.249 263.214 255.249Z"
                fill="#D1D3D4"
              />
              <path
                d="M167.639 173.845C168.85 173.845 169.832 172.865 169.832 171.656C169.832 170.448 168.85 169.468 167.639 169.468C166.428 169.468 165.446 170.448 165.446 171.656C165.446 172.865 166.428 173.845 167.639 173.845Z"
                fill="#FFF0D4"
              />
              <path
                d="M175.369 173.845C176.58 173.845 177.562 172.865 177.562 171.656C177.562 170.448 176.58 169.468 175.369 169.468C174.158 169.468 173.176 170.448 173.176 171.656C173.176 172.865 174.158 173.845 175.369 173.845Z"
                fill="#D1D3D4"
              />
              <path
                d="M183.098 173.845C184.309 173.845 185.291 172.865 185.291 171.656C185.291 170.448 184.309 169.468 183.098 169.468C181.887 169.468 180.905 170.448 180.905 171.656C180.905 172.865 181.887 173.845 183.098 173.845Z"
                fill="#D1D3D4"
              />
            </g>
            <g id="svg-right">
              <path
                d="M323.144 169.809H508.183V387.724C508.183 390.103 507.236 392.385 505.551 394.067C503.865 395.749 501.579 396.694 499.196 396.694H332.114C329.73 396.694 327.444 395.749 325.759 394.067C324.073 392.385 323.126 390.103 323.126 387.724V169.809H323.144Z"
                fill="white"
              />
              <path
                d="M499.196 397.591H332.024C329.405 397.586 326.895 396.544 325.045 394.695C323.195 392.845 322.156 390.338 322.156 387.724V169.809C322.156 169.571 322.25 169.343 322.419 169.175C322.588 169.006 322.816 168.912 323.055 168.912H508.183C508.422 168.912 508.65 169.006 508.819 169.175C508.987 169.343 509.082 169.571 509.082 169.809V387.724C509.077 390.34 508.034 392.846 506.181 394.696C504.328 396.545 501.816 397.586 499.196 397.591ZM324.043 170.706V387.76C324.043 389.898 324.893 391.949 326.406 393.462C327.919 394.975 329.972 395.828 332.114 395.833H499.285C501.429 395.828 503.484 394.976 505 393.463C506.516 391.95 507.37 389.9 507.374 387.76V170.706H324.043Z"
                fill="#CCCCCC"
              />
              <path
                d="M470.453 378.737H360.803C358.498 378.719 356.292 377.799 354.659 376.176C353.026 374.553 352.095 372.355 352.067 370.055V364.853C352.072 362.528 352.999 360.3 354.647 358.656C356.294 357.012 358.527 356.086 360.857 356.081H470.507C472.838 356.081 475.074 357.005 476.722 358.65C478.371 360.295 479.297 362.527 479.297 364.853V370.055C479.273 372.375 478.329 374.592 476.672 376.219C475.014 377.846 472.778 378.752 470.453 378.737ZM360.803 357.875C358.958 357.889 357.193 358.631 355.893 359.938C354.594 361.245 353.864 363.012 353.864 364.853V370.055C353.869 371.903 354.608 373.673 355.919 374.978C357.23 376.283 359.005 377.015 360.857 377.015H470.507C472.358 377.015 474.134 376.283 475.445 374.978C476.756 373.673 477.495 371.903 477.499 370.055V364.853C477.499 363.002 476.763 361.227 475.451 359.919C474.14 358.61 472.361 357.875 470.507 357.875H360.803Z"
                fill="#CCCCCC"
              />
              <path
                d="M364.272 229.526H349.604V335.021H364.272V229.526Z"
                fill="#FFF0D4"
              />
              <path
                d="M391.343 254.227H376.675V335.003H391.343V254.227Z"
                fill="#FFF0D4"
              />
              <path
                d="M418.396 238.136H403.729V335.021H418.396V238.136Z"
                fill="#FFF0D4"
              />
              <path
                d="M445.467 267.322H430.799V335.003H445.467V267.322Z"
                fill="#FFF0D4"
              />
              <path
                d="M472.538 238.136H457.87V335.021H472.538V238.136Z"
                fill="#FFF0D4"
              />
              <path
                d="M472.197 188.877H447.337C447.098 188.877 446.87 188.783 446.701 188.615C446.533 188.446 446.438 188.218 446.438 187.98C446.438 187.742 446.533 187.514 446.701 187.346C446.87 187.178 447.098 187.083 447.337 187.083H472.161C472.398 187.088 472.624 187.184 472.791 187.351C472.959 187.518 473.055 187.744 473.06 187.98C473.06 188.212 472.97 188.435 472.809 188.602C472.648 188.769 472.429 188.868 472.197 188.877Z"
                fill="#CCCCCC"
              />
              <path
                d="M463.515 198.115H447.337C447.098 198.115 446.87 198.021 446.701 197.853C446.533 197.685 446.438 197.456 446.438 197.219C446.438 196.981 446.533 196.753 446.701 196.584C446.87 196.416 447.098 196.322 447.337 196.322H463.515C463.753 196.322 463.982 196.416 464.15 196.584C464.319 196.753 464.413 196.981 464.413 197.219C464.413 197.456 464.319 197.685 464.15 197.853C463.982 198.021 463.753 198.115 463.515 198.115Z"
                fill="#CCCCCC"
              />
              <path
                d="M439.949 239.23C432.112 239.23 425.281 230.512 419.781 223.48C416.185 218.924 412.824 214.511 410.577 214.511C408.025 214.511 404.501 216.61 400.781 218.763C395.711 221.687 389.995 225.005 384.603 224.7C374.518 224.162 355.572 201.954 354.781 201.004C354.707 200.919 354.65 200.821 354.615 200.715C354.579 200.608 354.565 200.496 354.573 200.384C354.582 200.272 354.612 200.163 354.663 200.063C354.715 199.963 354.785 199.874 354.871 199.802C355.035 199.644 355.255 199.556 355.483 199.556C355.71 199.556 355.93 199.644 356.094 199.802C356.273 200.035 375.345 222.404 384.693 222.906C389.618 223.193 395.118 220 399.972 217.184C403.89 214.924 407.593 212.789 410.577 212.699C413.561 212.61 417.03 216.861 421.237 222.261C426.629 229.131 433.28 237.67 440.488 237.329C446.726 237.024 449.71 231.607 452.873 225.866C456.037 220.126 459.039 214.637 465.348 213.435C465.561 213.418 465.773 213.481 465.943 213.611C466.113 213.741 466.228 213.93 466.266 214.14C466.304 214.35 466.262 214.567 466.149 214.748C466.036 214.93 465.859 215.062 465.654 215.121C460.153 216.162 457.349 221.274 454.365 226.691C451.13 232.593 447.768 238.692 440.578 239.051L439.949 239.23Z"
                fill="#D1D3D4"
              />
              <path
                d="M485.085 335.865H337.687C337.574 335.865 337.463 335.842 337.359 335.799C337.256 335.755 337.162 335.692 337.083 335.611C337.004 335.531 336.942 335.436 336.901 335.332C336.86 335.227 336.84 335.116 336.842 335.004C336.851 334.785 336.944 334.577 337.101 334.424C337.258 334.271 337.467 334.183 337.687 334.178H485.085C485.314 334.178 485.534 334.269 485.695 334.431C485.857 334.592 485.948 334.811 485.948 335.039C485.946 335.15 485.922 335.259 485.877 335.361C485.832 335.462 485.768 335.554 485.688 335.63C485.608 335.707 485.513 335.767 485.41 335.807C485.307 335.847 485.196 335.867 485.085 335.865Z"
                fill="#D1D3D4"
              />
              <path
                d="M355.662 203.748C355.008 203.748 354.369 203.555 353.825 203.192C353.281 202.829 352.857 202.314 352.607 201.711C352.356 201.108 352.291 200.444 352.419 199.804C352.546 199.163 352.861 198.575 353.324 198.114C353.786 197.652 354.376 197.338 355.017 197.21C355.659 197.083 356.324 197.148 356.928 197.398C357.533 197.648 358.049 198.071 358.413 198.614C358.776 199.157 358.97 199.795 358.97 200.448C358.97 201.323 358.621 202.162 358.001 202.781C357.381 203.4 356.54 203.748 355.662 203.748ZM355.662 198.869C355.345 198.869 355.035 198.963 354.772 199.139C354.508 199.316 354.303 199.566 354.183 199.859C354.062 200.151 354.032 200.473 354.095 200.784C354.158 201.094 354.312 201.378 354.538 201.601C354.763 201.823 355.05 201.974 355.362 202.033C355.673 202.093 355.995 202.059 356.287 201.935C356.579 201.812 356.828 201.604 357.001 201.339C357.175 201.074 357.266 200.764 357.262 200.448C357.262 200.239 357.221 200.032 357.14 199.839C357.06 199.647 356.941 199.472 356.793 199.325C356.644 199.178 356.467 199.062 356.273 198.984C356.079 198.906 355.872 198.867 355.662 198.869Z"
                fill="#D1D3D4"
              />
              <path
                d="M465.312 217.758C464.659 217.755 464.021 217.558 463.479 217.193C462.938 216.828 462.517 216.312 462.269 215.708C462.022 215.105 461.959 214.442 462.088 213.803C462.218 213.163 462.534 212.577 462.998 212.117C463.461 211.657 464.05 211.345 464.691 211.219C465.332 211.093 465.996 211.159 466.6 211.41C467.203 211.66 467.719 212.083 468.081 212.626C468.444 213.168 468.637 213.805 468.637 214.457C468.633 215.334 468.28 216.174 467.657 216.792C467.034 217.411 466.191 217.758 465.312 217.758ZM465.312 212.861C464.996 212.861 464.686 212.955 464.423 213.13C464.16 213.305 463.955 213.555 463.834 213.846C463.713 214.138 463.681 214.459 463.743 214.769C463.805 215.079 463.957 215.363 464.181 215.586C464.404 215.81 464.689 215.962 465 216.023C465.31 216.085 465.632 216.053 465.924 215.932C466.216 215.812 466.466 215.607 466.642 215.344C466.818 215.082 466.912 214.773 466.912 214.457C466.912 214.034 466.743 213.628 466.443 213.329C466.143 213.029 465.736 212.861 465.312 212.861Z"
                fill="#D1D3D4"
              />
              <path
                d="M332.114 151.207H499.196C501.579 151.207 503.865 152.152 505.551 153.834C507.237 155.516 508.183 157.797 508.183 160.176V169.773H323.145V160.176C323.145 157.8 324.089 155.521 325.771 153.84C327.452 152.158 329.734 151.211 332.114 151.207Z"
                fill="white"
              />
              <path
                d="M508.183 170.706H323.144C322.906 170.706 322.677 170.611 322.509 170.443C322.34 170.275 322.246 170.047 322.246 169.809V160.176C322.246 157.562 323.285 155.055 325.135 153.206C326.985 151.356 329.495 150.314 332.114 150.31H499.285C501.906 150.314 504.418 151.355 506.271 153.205C508.124 155.054 509.167 157.561 509.172 160.176V169.773C509.178 169.902 509.156 170.03 509.107 170.149C509.059 170.269 508.985 170.377 508.892 170.465C508.798 170.554 508.686 170.621 508.564 170.662C508.441 170.704 508.312 170.719 508.183 170.706ZM324.043 168.912H507.285V160.176C507.28 158.036 506.426 155.986 504.91 154.473C503.394 152.96 501.339 152.108 499.196 152.104H332.024C329.882 152.108 327.829 152.961 326.316 154.474C324.803 155.988 323.953 158.038 323.953 160.176L324.043 168.912Z"
                fill="#CCCCCC"
              />
              <path
                d="M334.361 163.763C336.029 163.763 337.381 162.414 337.381 160.75C337.381 159.085 336.029 157.736 334.361 157.736C332.693 157.736 331.341 159.085 331.341 160.75C331.341 162.414 332.693 163.763 334.361 163.763Z"
                fill="#FFF0D4"
              />
              <path
                d="M344.841 163.763C346.509 163.763 347.861 162.414 347.861 160.75C347.861 159.085 346.509 157.736 344.841 157.736C343.173 157.736 341.821 159.085 341.821 160.75C341.821 162.414 343.173 163.763 344.841 163.763Z"
                fill="#D1D3D4"
              />
              <path
                d="M355.321 163.763C356.988 163.763 358.34 162.414 358.34 160.75C358.34 159.085 356.988 157.736 355.321 157.736C353.653 157.736 352.301 159.085 352.301 160.75C352.301 162.414 353.653 163.763 355.321 163.763Z"
                fill="#D1D3D4"
              />
              <path
                d="M413.184 208.053C412.978 208.052 412.78 207.975 412.626 207.838C412.454 207.692 412.346 207.484 412.326 207.259C412.306 207.034 412.375 206.811 412.519 206.636L420.284 197.452C420.366 197.355 420.468 197.278 420.582 197.225C420.697 197.173 420.823 197.146 420.949 197.147H440.38C440.619 197.147 440.847 197.241 441.016 197.41C441.184 197.578 441.279 197.806 441.279 198.044C441.279 198.282 441.184 198.51 441.016 198.678C440.847 198.846 440.619 198.941 440.38 198.941H421.308L413.795 207.91C413.616 208.033 413.398 208.084 413.184 208.053Z"
                fill="#D1D3D4"
              />
            </g>
            <g id="svg-left">
              <path
                d="M132.21 262.12H8.41338C4.76997 262.12 1.81641 265.067 1.81641 268.703V423.512C1.81641 427.147 4.76997 430.095 8.41338 430.095H132.21C135.853 430.095 138.807 427.147 138.807 423.512V268.703C138.807 265.067 135.853 262.12 132.21 262.12Z"
                fill="white"
              />
              <path
                d="M132.21 431.046H8.41322C6.42523 431.046 4.51866 430.258 3.11293 428.855C1.70721 427.452 0.91748 425.549 0.91748 423.565V268.703C0.922227 266.721 1.71348 264.821 3.11818 263.419C4.52287 262.017 6.42669 261.228 8.41322 261.223H132.21C134.196 261.228 136.1 262.017 137.505 263.419C138.91 264.821 139.701 266.721 139.706 268.703V423.512C139.713 424.498 139.524 425.477 139.151 426.391C138.777 427.304 138.226 428.135 137.529 428.836C136.833 429.536 136.004 430.092 135.091 430.471C134.178 430.85 133.199 431.046 132.21 431.046ZM8.41322 263.017C6.90342 263.021 5.45682 263.622 4.38922 264.687C3.32163 265.753 2.71976 267.196 2.71502 268.703V423.512C2.7079 424.263 2.85002 425.008 3.13319 425.704C3.41636 426.4 3.83496 427.034 4.3648 427.567C4.89464 428.101 5.52521 428.525 6.22008 428.814C6.91496 429.103 7.66037 429.252 8.41322 429.252H132.21C133.721 429.252 135.171 428.653 136.239 427.586C137.308 426.52 137.908 425.074 137.908 423.565V268.703C137.903 267.196 137.301 265.753 136.234 264.687C135.166 263.622 133.72 263.021 132.21 263.017H8.41322Z"
                fill="#CCCCCC"
              />
              <path
                d="M104.276 394.613H30.451C30.2126 394.613 29.984 394.518 29.8155 394.35C29.6469 394.182 29.5522 393.954 29.5522 393.716C29.5568 393.479 29.653 393.254 29.8206 393.087C29.9881 392.92 30.2141 392.824 30.451 392.819H104.276C104.514 392.819 104.743 392.913 104.912 393.082C105.08 393.25 105.175 393.478 105.175 393.716C105.175 393.954 105.08 394.182 104.912 394.35C104.743 394.518 104.514 394.613 104.276 394.613Z"
                fill="#D1D3D4"
              />
              <path
                d="M68.6308 406.649H30.451C30.2141 406.645 29.9881 406.549 29.8206 406.382C29.653 406.214 29.5568 405.989 29.5522 405.753C29.5522 405.515 29.6469 405.287 29.8155 405.118C29.984 404.95 30.2126 404.856 30.451 404.856H68.6308C68.8692 404.856 69.0978 404.95 69.2663 405.118C69.4349 405.287 69.5296 405.515 69.5296 405.753C69.525 405.989 69.4288 406.214 69.2612 406.382C69.0937 406.549 68.8677 406.645 68.6308 406.649Z"
                fill="#D1D3D4"
              />
              <path
                d="M35.017 299.934C45.8023 310.697 56.5456 321.424 67.2469 332.115C67.4108 332.293 67.6113 332.434 67.8348 332.526C68.0582 332.619 68.2993 332.663 68.5412 332.654H117.92C117.649 340.265 115.549 347.7 111.797 354.332C108.044 360.965 102.75 366.6 96.3584 370.762C89.9672 374.925 82.667 377.494 75.0728 378.252C67.4787 379.01 59.8132 377.936 52.7223 375.119C45.6314 372.302 39.3228 367.826 34.3282 362.067C29.3337 356.308 25.7994 349.435 24.0232 342.028C22.2471 334.621 22.2811 326.896 24.1224 319.505C25.9638 312.113 29.5585 305.272 34.6036 299.557L35.017 299.934Z"
                fill="#FFF0D4"
              />
              <path
                d="M72.0283 283.377V329.263H72.4957L117.003 329.156C117.991 329.156 117.991 329.156 117.92 328.169C117.358 319.442 114.415 311.034 109.409 303.856C104.403 296.679 97.5244 291.005 89.5184 287.449C84.6315 285.319 79.4273 284.003 74.1135 283.556L72.0283 283.377Z"
                fill="#EEEEEE"
              />
              <path
                d="M68.6311 328.653V283.413C63.2848 283.689 58.0145 284.797 53.0104 286.695C47.0245 289.059 41.5638 292.576 36.9404 297.046L68.6311 328.653Z"
                fill="#EEEEEE"
              />
            </g>
            <path
              d="M421.363 509.688H356.13C355.225 509.69 354.329 509.514 353.492 509.169C352.656 508.825 351.896 508.319 351.256 507.681C350.617 507.042 350.11 506.284 349.764 505.449C349.419 504.615 349.243 503.72 349.245 502.817V430.902C349.245 430 349.423 429.107 349.769 428.273C350.115 427.439 350.622 426.682 351.262 426.044C351.901 425.406 352.66 424.9 353.495 424.555C354.33 424.209 355.226 424.032 356.13 424.032H413.651L428.175 438.167V502.745C428.185 503.648 428.016 504.543 427.679 505.38C427.342 506.217 426.842 506.98 426.21 507.625C425.577 508.269 424.823 508.783 423.992 509.137C423.16 509.491 422.267 509.678 421.363 509.688Z"
              fill="white"
            />
            <path
              d="M421.362 510.585H356.13C354.065 510.585 352.086 509.766 350.626 508.31C349.166 506.853 348.346 504.877 348.346 502.817V430.902C348.351 428.844 349.172 426.871 350.631 425.415C352.09 423.959 354.067 423.14 356.13 423.135H413.651C413.767 423.132 413.883 423.153 413.991 423.196C414.099 423.239 414.198 423.304 414.28 423.386L428.822 437.539C428.99 437.706 429.086 437.931 429.092 438.167V502.746C429.106 504.807 428.3 506.79 426.851 508.26C425.402 509.73 423.428 510.566 421.362 510.585ZM356.13 424.929C354.543 424.933 353.024 425.564 351.902 426.684C350.781 427.803 350.148 429.319 350.144 430.902V502.799C350.144 503.584 350.299 504.361 350.599 505.085C350.9 505.81 351.341 506.469 351.897 507.023C352.453 507.578 353.113 508.018 353.839 508.318C354.565 508.618 355.344 508.773 356.13 508.773H421.362C422.948 508.768 424.468 508.137 425.59 507.018C426.711 505.899 427.343 504.382 427.348 502.799V438.544L413.345 424.929H356.13Z"
              fill="#CCCCCC"
            />

            <path
              d="M413.723 424.032V438.598L428.247 438.167L413.723 424.032Z"
              fill="#FFF0D4"
            />
            <path
              d="M413.704 439.495C413.476 439.493 413.257 439.403 413.093 439.244C413.002 439.162 412.93 439.063 412.88 438.951C412.83 438.84 412.805 438.72 412.806 438.598V424.032C412.809 423.855 412.863 423.683 412.962 423.537C413.061 423.391 413.2 423.276 413.363 423.207C413.524 423.136 413.703 423.116 413.876 423.148C414.049 423.18 414.208 423.263 414.334 423.386L428.876 437.539C428.998 437.664 429.081 437.821 429.116 437.991C429.15 438.162 429.134 438.339 429.069 438.501C429.004 438.663 428.893 438.802 428.751 438.902C428.608 439.002 428.439 439.058 428.265 439.064L413.74 439.495H413.704ZM414.603 426.166V437.683L426.089 437.342L414.603 426.166Z"
              fill="#CCCCCC"
            />
            <path
              d="M99.3329 461.918H80.8721C78.1619 461.918 75.9648 464.11 75.9648 466.815V485.238C75.9648 487.942 78.1619 490.135 80.8721 490.135H99.3329C102.043 490.135 104.24 487.942 104.24 485.238V466.815C104.24 464.11 102.043 461.918 99.3329 461.918Z"
              fill="#FFF0D4"
            />
            <path
              d="M94.4612 452.877H75.3893C72.8479 452.877 70.7876 454.933 70.7876 457.469V476.502C70.7876 479.038 72.8479 481.094 75.3893 481.094H94.4612C97.0027 481.094 99.0629 479.038 99.0629 476.502V457.469C99.0629 454.933 97.0027 452.877 94.4612 452.877Z"
              fill="white"
            />
            <path
              d="M94.4614 481.991H75.3895C74.6641 481.996 73.945 481.857 73.2735 481.583C72.602 481.31 71.9913 480.906 71.4768 480.396C70.9622 479.886 70.5538 479.279 70.2751 478.611C69.9965 477.942 69.853 477.226 69.853 476.502V457.469C69.853 456.013 70.4325 454.617 71.4641 453.588C72.4956 452.558 73.8947 451.98 75.3535 451.98H94.4254C95.1477 451.98 95.863 452.122 96.5304 452.398C97.1977 452.674 97.8041 453.078 98.3148 453.588C98.8256 454.097 99.2308 454.702 99.5072 455.368C99.7836 456.034 99.9259 456.748 99.9259 457.469V476.502C99.9259 477.951 99.3513 479.342 98.3276 480.37C97.3038 481.399 95.914 481.981 94.4614 481.991ZM75.3895 453.774C74.9002 453.769 74.4148 453.861 73.9614 454.045C73.5079 454.228 73.0955 454.5 72.7478 454.843C72.4001 455.187 72.1242 455.596 71.9358 456.047C71.7475 456.497 71.6505 456.981 71.6506 457.469V476.502C71.6506 477.482 72.0407 478.422 72.7351 479.115C73.4296 479.808 74.3714 480.197 75.3535 480.197H94.4254C95.4075 480.197 96.3494 479.808 97.0438 479.115C97.7382 478.422 98.1284 477.482 98.1284 476.502V457.469C98.1284 456.489 97.7382 455.549 97.0438 454.856C96.3494 454.163 95.4075 453.774 94.4254 453.774H75.3895Z"
              fill="#CCCCCC"
            />
            <path
              d="M384.854 493.633V489.399C383.308 488.969 381.816 488.664 380.36 488.162C377.551 487.285 375.12 485.491 373.458 483.067C372.09 480.927 371.395 478.429 371.462 475.892H379.749C379.803 477.33 380.331 478.711 381.251 479.82C382.17 480.929 383.43 481.704 384.836 482.027V470.797C383.164 470.313 381.511 469.864 379.857 469.344C378.055 468.826 376.403 467.885 375.04 466.6C373.625 465.187 372.689 463.368 372.361 461.397C371.881 459.003 372.061 456.523 372.882 454.222C373.493 452.565 374.461 451.063 375.72 449.823C376.978 448.583 378.495 447.635 380.163 447.047C381.539 446.614 382.937 446.255 384.351 445.97L384.854 445.863V440.015H392.619V440.571C392.619 442.167 392.619 443.782 392.619 445.378C392.619 445.773 392.745 445.899 393.123 445.988C395.763 446.499 398.234 447.658 400.313 449.361C402.525 451.164 403.991 453.72 404.429 456.536C404.573 457.308 404.627 458.097 404.735 458.904H396.304C396.291 457.756 395.944 456.637 395.306 455.682C394.668 454.727 393.766 453.977 392.709 453.523V464.016L393.626 464.232C395.905 464.672 398.125 465.376 400.241 466.331C401.83 467.018 403.201 468.125 404.208 469.531C405.215 470.937 405.82 472.59 405.957 474.313C406.454 477.523 405.673 480.8 403.782 483.444C402.226 485.594 400.041 487.211 397.527 488.072C395.963 488.61 394.345 488.951 392.673 489.417V493.525L384.854 493.633ZM392.637 472.304V482.063C393.9 481.878 395.088 481.351 396.071 480.538C396.578 480.126 396.983 479.603 397.255 479.01C397.527 478.417 397.658 477.769 397.639 477.117C397.62 476.465 397.451 475.826 397.145 475.249C396.838 474.673 396.403 474.175 395.873 473.793C394.83 473.222 393.749 472.725 392.637 472.304ZM384.836 462.312V452.877C383.819 453.321 382.829 453.824 381.87 454.384C381.32 454.775 380.875 455.297 380.577 455.902C380.278 456.507 380.135 457.177 380.16 457.851C380.185 458.525 380.377 459.182 380.72 459.763C381.062 460.345 381.544 460.832 382.122 461.182C382.967 461.631 383.883 461.936 384.836 462.33V462.312Z"
              fill="#FFF0D4"
            />
            <defs>
              <filter
                id="filter0_df"
                x="133"
                y="0"
                width="417"
                height="400"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dx="22" dy="4" />
                <feGaussianBlur stdDeviation="44.5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.940951 0 0 0 0 0.923976 0 0 0 0 0.917611 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="47"
                  result="effect2_foregroundBlur"
                />
              </filter>
              <linearGradient
                id="paint0_linear"
                x1="333"
                y1="94"
                x2="333"
                y2="306"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFF6E6" />
                <stop offset="0.40625" stop-color="white" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadlineText from "./HeadlineText.vue";

export default {
  name: "strategy-section",
  data() {
    return {
      texts: [
        {
          title: "strategy.analyse",
          body: "strategy.analyseText",
        },
        {
          title: "strategy.concept",
          body: "strategy.conceptText",
        },
        {
          title: "strategy.digitize",
          body: "strategy.digitizeText",
        },
      ],
    };
  },
  mounted: function() {
    this.animate();
  },
  components: { HeadlineText },
  methods: {
    animate() {},
  },
};
</script>
<style scoped>
#strategy {
  background: url("../assets/img/bg-grey-top.png") center center;
  background-size: cover;
}
</style>
