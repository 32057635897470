export default {
  "navigation": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "strategy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategie"])},
    "expertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise"])},
    "references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenzen"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bücher"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo, ich bin David. <br />IT Berater & Lösungsarchitekt."])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte Unternehmen mit hochmoderner Technologie <br/> und Software auf ihrem Weg zum Erfolg begleiten <br/> und Kunden ein optimales Nutzererlebnis bieten."])}
  },
  "strategy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Strategie"])},
    "analyse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysieren"])},
    "analyseText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zunächst ist es unerlässlich bereits bestehende Prozesse kennenzulernen. Oftmals werden wichtige Prozessschritte, die nur dem direkten Anwender bekannt sind, übersehen. Eine gut durchgeführte Prozessanalyse ermöglicht es mir, den wahren Umfang der Prozesse zu kennen."])},
    "concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konzipieren"])},
    "conceptText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiches Geschäft entsteht durch innovative Ideen, zukunftsfähige Strategien und passgenaue IT-Lösungen, die Unternehmen optimal bei Ihren individuellen Herausforderungen unterstützen. Ich bin daran beteiligt, weil ich den richtigen Mix aus Technologieexpertise und fundiertem Verständnis für Geschäftsmodelle mitbringe."])},
    "digitize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalisieren"])},
    "digitizeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalisieren bedeutet eine Neuausrichtung der Unternehmensprozesse mit Hilfe modernster Technologien. Das bedeutet zum einen, dass neue Werkzeuge angeschafft werden müssen, und zum anderen, dass sich die Anforderungen an Mitarbeiter verändern können. Durch agile Methoden schaffen wir einen iterativen und zielorientierten Prozess."])}
  },
  "expertise": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Expertise"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dank meinen bisherigen Erfahrungen in diversen Bereichen der IT und des Businessmanagements kann ich ein breit gefächertes Portfolio von Kenntnissen und Fähigkeiten vorweisen. Die Vielseitigkeit der digitalen Welt fasziniert mich. Ich bin immer auf der Suche nach neuen spannenden Herausforderungen, bei denen mein lösungsorientiertes Denken, meine Leidenschaft für Kreativität und Design sowie meine Freude an Teamarbeit gefragt sind."])},
    "databases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenbanken"])},
    "devops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DevOps & Cloud"])},
    "backend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend"])},
    "backendAndDB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend & DB"])},
    "frontend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend"])},
    "focus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwerpunkte"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])}
  },
  "references": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige meiner Referenzen"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keines meiner Projekte war ähnlich – alle herausfordernd und hoch attraktiv. Das Privileg, an so vielen verschiedenen Produkten arbeiten zu dürfen, ermöglichte es mir, mein Wissen azuwenden und Probleme kreativ zu lösen."])}
  },
  "books": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus meinem Bücherregal"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Laufe der Jahre haben mir viele Bücher geholfen, ein solides Verständnis von Tools, Prinzipien und Best Practices rundum Technologien und Unternehmertum zu erlangen. Daher habe ich eine kleine Liste meiner Lieblingsbücher angefertigt, die ich jedem nur empfehlen kann.<br /><br />Zusätzlich hatte ich das große Vergnügen, mein eigenes Buch zu schreiben, welches 10 Krav Maga Prinzipien für eine erfolgreiche Führungskraft vorstellt."])}
  },
  "blog": {
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registiere Dich für 'David the Techie'"])},
    "newsletterText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen an einem magischen Ort, an dem Faulheit als Effizienz neu definiert wird und jeder Newsletter ein Ausbruch von Technikinspiration, Problemlösungstricks und Unternehmergeist ist. Abonniere für deine wöchentliche Dosis an Innovation und befreienden Einsichten."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Blog"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier finden Sie einige spannende Artikel Rund um Technologien, Projekt Management und IT-Architektur."])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])}
  }
}