<template>
  <div id="hero" class="w-100">
    <div class="container h-100">
      <div class="h-100 d-flex justify-content-center flex-column">
        <h2 v-html="$t('home.title')" />
        <p class="mt-3" v-html="$t('home.introduction')" />
        <ul
          class="list-group list-unstyled list-group-horizontal mt-3 social-icons"
        >
          <li v-for="(icon, index) in socialIcons" v-bind:key="index">
            <social-icon v-bind:icon="icon.name" v-bind:url="icon.url" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import SocialIcon from "./SocialIcon.vue";
export default {
  name: "hero-section",
  data() {
    return {
      socialIcons: [
        {
          url: "https://www.linkedin.com/in/davidminkovski/",
          name: "linkedin",
        },
        {
          url: "https://www.xing.com/profile/David_Minkovski/cv",
          name: "xing",
        },
        {
          url: "https://minkovski-d.medium.com/",
          name: "medium",
        },
      ],
    };
  },
  props: {},
  components: { SocialIcon },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#hero {
  height: 100vh;
  background: url("../assets/img/hero.png") center center;
  background-size: cover;
}
ul li {
  margin-right: 30px;
}
p {
  font-size: 26px;
}
@media screen and (max-width: 400px) {
  h2 {
    text-align: center;
  }
  p {
    font-size: 20px;
    text-align: center;
  }
  .social-icons {
    justify-content: center;
  }
}
</style>
