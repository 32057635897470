<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-transparent fixed-top">
    <div class="container">
      <a class="navbar-brand" href="/#">
        <img
          src="../assets/img/logo.png"
          alt=""
          width="35"
          height="30"
          class="d-inline-block align-text-top"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav list-unstyled align-items-center">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="/#strategy">{{
              $t("navigation.strategy")
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#expertise">{{
              $t("navigation.expertise")
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#references">{{
              $t("navigation.references")
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#buecherregal">{{
              $t("navigation.books")
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#blog">{{ $t("navigation.blog") }}</a>
          </li>
          <li
            class=""
            v-for="locale in locales"
            :key="locale"
            @click="switchLocale(locale)"
          >
            <a class="nav-item" v-if="$i18n.locale !== locale.key">
              {{ locale.name }}</a
            >
          </li>
          <li class="nav-item pe-0 mt-3 mt-sm-0">
            <a
              id="btn-contact"
              class="nav-link"
              href="mailto:minkovski.d@gmail.com"
              >{{ $t("navigation.contact") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navigation-bar",
  props: {},
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale.key) {
        this.$i18n.locale = locale.key;
      }
    },
  },
  data() {
    return {
      locales: [
        { key: "en", name: "English" },
        { key: "de", name: "Deutsch" },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  top: 50px;
}
nav .nav-item {
  padding: 0 25px;
}
nav .nav-item .nav-link {
  padding: 0px 0px;
  color: #000;
}
#btn-contact {
  border: 2px solid #000;
  border-radius: 20px;
  padding: 5px 25px;
  vertical-align: middle;
}
@media screen and (max-width: 400px) {
  nav {
    top: 0;
  }
  .nav-item {
    margin-bottom: 15px;
  }
}
a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
</style>
