<template>
  <li class="tag" v-bind:key="index">
    <slot />
  </li>
</template>

<script>
export default {
  name: "tag",
  props: {
    index: Number,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped components>
.tag {
  background: rgb(230, 230, 230);
  color: rgba(109, 110, 110, 1);
  font-size: 13px;
  border-radius: 3px;
  list-style-type: none;
  padding: 5px 10px;
  display: inline-block;
  margin: 4px 4px;
}
</style>
