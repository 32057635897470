<template>
  <footer id="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 bg-light">
          <div class="row">
            <div class="col-12 col-md-6">
              <label>Navigation</label>
              <ul class="list-unstyled mt-3">
                <li>
                  <router-link to="/">{{ $t("navigation.home") }}</router-link>
                </li>
                <li>
                  <router-link to="/imprint">{{
                    $t("navigation.imprint")
                  }}</router-link>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6">
              <ul class="list-unstyled">
                <li><img width="137" src="../assets/img/allianz.png" /></li>
                <li class="mt-3">
                  <img
                    width="137"
                    src="https://siegel.siwecos.de/www.siwecos.de/d.m.y.svg"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 bg-darker">
          <div class="">
            <label>{{ $t("navigation.contact") }}</label>
            <p class="mt-3">
              E:
              <a href="mailto:minkovski.d@gmail.com"
                >minkovski[dot]d[at]gmail[dot]com</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <nav></nav>
</template>

<script>

export default {
  name: "footer-bar"
};
</script>

<style scoped>
label {
  font-size: 12px;
  font-weight: bold;
  color: #8c8d8f;
  font-family: "Open Sans";
}
a {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
  color: #1f2225;
  text-decoration: none;
}
.bg-light {
  background: #f8f8f8;
  padding: 50px 100px;
  font-family: "Open Sans";
}
.bg-darker {
  background: #ebebeb;
  padding: 50px 100px;
}
</style>
