<template>
  <div id="imprint">
    <div class="container">
      <div class="row">
        <h2>Imprint</h2>
        <p>
          Angaben gemäß § 5 TMG:<br />David Minkovski<br />
          E-Mail:
          <a href="mailto:minkovski.d@gmail.com"
            >minkovski[dot]d[at]gmail[dot]com</a
          ><br />USt.-ID: 219/5223/3867
        </p>
        <p>Organisation, Development and Management in Software Development.</p>
        <p>
          Diese Website wurde von David Minkovski entwickelt und administriert.
          Eine Haftung oder Garantie für die Aktualität, Richtigkeit und
          Vollständigkeit der auf dieser Website zur Verfügung gestellten
          Informationen und Daten ist ausgeschlossen. Dies gilt ebenso für
          Websites, auf die mittels Hyperlink verwiesen wird. Für den Inhalt der
          Websites anderer Unternehmen, auf die verlinkt wird, ist David nicht
          verantwortlich.
        </p>
        <p>
          David behält sich vor, ohne Ankündigung Teile ihrer Website oder das
          gesamte Angebot zu verändern, zu ergänzen oder zu löschen. Alle
          Angebote sind freibleibend und unverbindlich. Jegliche Haftung,
          insbesondere für eventuelle Schäden oder Konsequenzen, die durch die
          Nutzung der Inhalte der Website entstehen, sind ausgeschlossen.
        </p>
        <p>
          Der Inhalt der Website ist urheberrechtlich geschützt. Die
          Vervielfältigung oder Verwendung der Inhalte in anderen elektronischen
          oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung von
          David ist nicht gestattet. Alle innerhalb des Internetangebotes
          genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen
          unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen
          Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen
          Eigentümer.
        </p>
        <h3>Haftung für Inhalte</h3>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen.
        </p>
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </p>
        <h3>Haftung für Links</h3>
        <p>
          Unser Angebot enthält Links zu externen Websites Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
        </p>
        <p>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <h3>Urheberrecht</h3>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        </p>
        <p>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imprint",
  props: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#imprint {
  padding-top: 150px;
}
</style>
